export const faqDataJS = {
  data: {
    // payment
    intoduction: {
      icon: 'HelpCircleIcon',
      title: 'C\'est Quoi PharmaConnect ?',
      // subtitle: 'PharmaConnect vue gblobale',
      qandA: [
        {
          question: 'PharmaConnect : La Révolution Digitale dans la Pharmacie Tunisienne',
          ans:
            "PharmaConnect est bien plus qu'une simple plateforme de communication entre les pharmaciens et les grossistes. C'est une révolution digitale qui transforme le secteur pharmaceutique en Tunisie. Grâce à PharmaConnect, les pharmaciens peuvent désormais passer leurs commandes chez n'importe quel grossiste en un seul endroit, simplifiant ainsi leur travail quotidien. De plus, PharmaConnect offre de nombreuses autres fonctionnalités qui rendent la vie des pharmaciens plus simple et plus efficace. Découvrez comment PharmaConnect réinvente la pharmacie en Tunisie et contribue à la digitalisation du secteur pharmaceutique.",
        },
      ],
    },
    // delivery
    authentification: {
      icon: 'LockIcon',
      title: 'Authentification Unique (SSO)',
      // subtitle: 'Which license do I need?',
      qandA: [
        {
          question: 'Authentification Unique (SSO) : Accédez à PharmaConnect en Toute Sécurité',
          ans:
            "Pour accéder à l'univers numérique de PharmaConnect, chaque pharmacien recevra un e-mail contenant ses informations d'identification, notamment un nom d'utilisateur et un mot de passe. Une fois ces données saisies dans la page d'authentification, le pharmacien pourra explorer toutes les fonctionnalités de PharmaConnect. Lors de la première connexion, il sera invité à modifier son mot de passe pour garantir une sécurité maximale, comme illustré dans la photo. Découvrez comment l'authentification unique (SSO) simplifie la connexion tout en renforçant la sécurité de votre compte PharmaConnect.",
        },
      ],
    },
    // cancellation and return
    network: {
      icon: 'Share2Icon',
      title: 'Réseau Magique',
      // subtitle: 'Which license do I need?',
      qandA: [
        {
          question: 'Connectez-vous au Réseau Magique de PharmaConnect',
          ans:
            "Dès que vous accédez à PharmaConnect, vous entrez dans un univers interconnecté qui vous relie à tous les autres pharmaciens ainsi qu'à une multitude de grossistes. La magie de PharmaConnect se révèle dès le départ : dans l'en-tête de la plateforme, vous trouverez le nom du grossiste avec lequel vous êtes actuellement connecté. En cliquant sur ce nom, une liste déroulante apparaît, vous permettant de choisir parmi les grossistes inscrits à PharmaConnect avec lesquels vous avez déjà établi une relation client. Sélectionnez simplement le grossiste répartiteur de votre choix, et la plateforme se charge de charger ses données et ses coordonnées. Vous pouvez ainsi naviguer, passer des commandes et profiter de toutes les fonctionnalités offertes par PharmaConnect, sans avoir besoin de vous authentifier à nouveau. Fini les dizaines de portails différents pour chaque grossiste ; avec PharmaConnect, tous les grossistes sont réunis en un seul endroit, plus proches de vous que jamais. Découvrez le futur de la pharmacie, simplifié et interconnecté grâce à PharmaConnect.",
        },
      ],
    },
    // my orders
    order: {
      icon: 'ShoppingBagIcon',
      title: 'Prise de Commande',
      // subtitle: 'Which license do I need?',
      qandA: [
        {
          question: 'Prise de Commande Instantanée',
          ans:
            "Lorsque vous saisissez une commande sur PharmaConnect, sachez que celle-ci est transférée instantanément au grossiste sélectionné dans l'en-tête de la plateforme. Plus besoin d'attendre ni de passer par des processus compliqués ; votre commande est traitée en temps réel pour une expérience de commande fluide et efficace. Pharmaciens et grossistes sont connectés comme jamais auparavant grâce à PharmaConnect.",
        },
        {
          question: "1 - Recherche d'Articles",
          ans:
            'Ici, vous pouvez rechercher des articles par leur désignation. Saisissez simplement le nom de l\'article dans la barre de recherche, et les résultats correspondants s\'afficheront pour vous aider à trouver rapidement ce que vous cherchez.',
        },
        {
          question: '2 - Lignes de Commande',
          ans:
            'Une fois que vous avez sélectionné un article, il apparaîtra dans ce tableau. Les lignes de commande représentent les articles que vous avez ajoutés à votre commande. Vous pouvez également spécifier la quantité de chaque article et d\'autres détails.',
        },
        {
          question: '3 - Saisie de la Quantité',
          ans:
            'Pour spécifier la quantité d\'un article que vous souhaitez commander, double-cliquez sur la cellule "Quantité" correspondant à cet article. Ensuite, saisissez la quantité souhaitée à l\'aide de votre clavier.Une fois que vous avez entré la quantité, appuyez sur la touche "Entrée" pour confirmer votre choix.La quantité sera enregistrée dans la commande.',
        },
        {
          question: '4 - Vérification de la Quantité',
          ans:
            'Après avoir saisi la quantité souhaitée, le système vérifiera automatiquement si le répartiteur possède suffisamment de stock de cet article. En haut à droite de la page, vous verrez un indicateur qui vous montrera le résultat de cette vérification. Si le répartiteur a la quantité requise, une icône en forme de verre s\'affichera à gauche de la ligne de l\'article. Si la quantité n\'est pas disponible, vous verrez une icône en rouge indiquant que l\'article est en rupture de stock.',
        },
        {
          question: '5 - Articles de Substitution',
          ans:
            'Si l\'article que vous recherchez n\'est pas disponible en stock, vous pouvez cliquer sur sa description dans la colonne "Description". Le système vous affichera automatiquement une liste d\'articles de substitution possibles pour remplacer celui-ci.',
        },
        {
          question: '6 - Notifications de Promotion',
          ans:
            'Si un article est en promotion, le système affiche une notification en haut à droite de la page. Par exemple, "Promo 5->1". Dans la colonne "Gratuité", vous trouverez des informations sur les avantages de la promotion. Par exemple, si vous saisissez une quantité qui garantit des articles gratuits, le système affiche "Vous avez bénéficié de n articles gratuits".',
        },
        {
          question: '7 - Supprimer un Article',
          ans:
            'À droite de chaque ligne, vous verrez une icône en rouge. Cliquez sur cette icône pour supprimer l\'article de la commande.',
        },
        {
          question: '8 - Passer la Commande',
          ans:
            "Après avoir préparé les articles que vous souhaitez commander, cliquez sur le bouton 'Commander' à droite de la rubrique de recherche pour poursuivre la validation. Veuillez noter que ce bouton sera activé uniquement si tous les articles de la commande sont en vert, ce qui signifie que les quantités sont disponibles et réservées.",
        },
        {
          question: '9 - Validation Finale',
          ans:
            "Enfin, pour la validation finale, une fenêtre contextuelle s'affiche contenant la liste des articles que vous avez préparés. Vous pouvez ajouter une remarque ou une observation en bas de la liste, qui sera prise en compte par le responsable de la gestion de la commande. Une fois que vous avez vérifié la commande, cliquez sur le bouton 'Valider la Commande'.",
        },
        {
          question: 'Expérience Agréable',
          ans:
            "C'est tout ! Nous vous souhaitons une expérience agréable sur la plateforme PharmaConnect. Si vous avez besoin d'informations supplémentaires ou si vous avez des réclamations, n'hésitez pas à nous contacter. Nous sommes là pour vous aider.",
        },
      ],
    },
  },
}

