<template>
  <div v-if="options">
    <div class="d-flex align-items-center">
      <b-avatar rounded size="42" variant="light-primary" class="mr-1">
        <feather-icon :icon="options.icon" size="20" />

      </b-avatar>
      <div>
        <h4 class="mb-0">
          {{ options.title }}
        </h4>
        <span>{{ options.subtitle }}</span>
      </div>
    </div>

    <b-card class="mt-2" no-body v-for="( data, index) in options.qandA" :key="index">
      <div class="card-header">
        <div>
          <b-card-title>{{ data.question }}</b-card-title>
        </div>
      </div>
      <b-card-body>
        {{ data.ans }}
        <br>
        <br>
        <div v-if="data.question.includes('Authentification')">
          <b-img :src="auth1" alt="logo" style="width: 100%;" />
          <br>
          <br>
          <b-img :src="auth2" alt="logo" style="width: 100%;" />
        </div>
        <b-img :src="img1" alt="logo" style="width: 100%;"
          v-if="data.question == 'Connectez-vous au Réseau Magique de PharmaConnect'" />
        <b-img :src="img2" alt="logo" style="width: 100%;"
          v-if="data.question == 'Connectez-vous au Réseau Magique de PharmaConnect'" />

          <b-img :src="order1" alt="logo" style="width: 100%;"
          v-if="data.question == 'Prise de Commande Instantanée'" />

          <b-img :src="order2" alt="logo" style="width: 100%;"
          v-if="data.question == '1 - Recherche d\'Articles'" />

          <b-img :src="order3" alt="logo" style="width: 100%;"
          v-if="data.question == '2 - Lignes de Commande'" />

          <b-img :src="order4" alt="logo" style="width: 100%;"
          v-if="data.question == '3 - Saisie de la Quantité'" />

          <b-img :src="order5" alt="logo" style="width: 100%;"
          v-if="data.question == '4 - Vérification de la Quantité'" />

          <b-img :src="order6" alt="logo" style="width: 100%;"
          v-if="data.question == '5 - Articles de Substitution'" />

          <b-img :src="order7" alt="logo" style="width: 100%;"
          v-if="data.question == '6 - Notifications de Promotion'" />

          <b-img :src="order8" alt="logo" style="width: 100%;"
          v-if="data.question == '7 - Supprimer un Article'" />

          <b-img :src="order9" alt="logo" style="width: 100%;"
          v-if="data.question == '8 - Passer la Commande'" />

          <b-img :src="order10" alt="logo" style="width: 100%;"
          v-if="data.question == '9 - Validation Finale'" />
      </b-card-body>

    </b-card>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BAvatar,
    // AppCollapseItem,
    // AppCollapse,
  },
  props: {
    options: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      img1: require('@/assets/images/guide/1.png'),
      img2: require('@/assets/images/guide/2.png'),
      auth1: require('@/assets/images/guide/auth1.png'),
      auth2: require('@/assets/images/guide/authh.png'),
      order1: require('@/assets/images/guide/order1.png'),
      order2: require('@/assets/images/guide/order2.png'),
      order3: require('@/assets/images/guide/order3.png'),
      order4: require('@/assets/images/guide/order4.png'),
      order5: require('@/assets/images/guide/order5.png'),
      order6: require('@/assets/images/guide/order6.png'),
      order7: require('@/assets/images/guide/order7.png'),
      order8: require('@/assets/images/guide/order8.png'),
      order9: require('@/assets/images/guide/order9.png'),
      order10: require('@/assets/images/guide/order10.png'),
    }
  }
}
</script>
