<template>
  <div>
    <h1 class="visually-hidden">FAQ - PharmaConnect : C'est Quoi PharmaConnect ?</h1>

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs vertical content-class="col-12 col-md-8 col-lg-9" pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12" nav-class="nav-left">

        <!-- payment tab -->
        <b-tab v-for="(categoryObj, categoryName, index) in faqData" :key="categoryName" :active="!index">

          <!-- title -->
          <template #title>
            <feather-icon :icon="categoryObj.icon" size="18" class="mr-1" />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img fluid :src="require('@/assets/images/faq/faq-illustrations.svg')" class="d-none d-md-block mt-auto" />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>Vous avez encore une question ?</h2>
          <b-card-text class="mb-3">
            Si vous ne trouvez pas une question dans notre FAQ, vous pouvez toujours nous contacter. Nous reviendrons
            vers
            vous bientôt!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="PhoneCallIcon" size="18" />
            </b-avatar>
            <h4>+216 22-283-332</h4>
            <span class="text-body">Nous sommes ravis de pouvoir vous assister!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="MailIcon" size="18" />
            </b-avatar>
            <h4>contact@pharmaconnect.net</h4>
            <span class="text-body">Le meilleur moyen d'obtenir une réponse plus rapidement!</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>

import FaqQuestionAnswer from './FaqQuestionAnswer.vue'
import { faqDataJS } from "./faq-data.js"

export default {

  metaInfo() {
    return {
      title: "FAQ - PharmaConnect : C'est Quoi PharmaConnect ?",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Découvrez ce qu'est PharmaConnect, la plateforme qui révolutionne la pharmacie en Tunisie. PharmaConnect facilite la communication entre les pharmaciens et les grossistes, simplifiant le processus de commande et offrant de nombreuses fonctionnalités innovantes.",
        },
        {
          name: "robots",
          content: "index, follow",
        },
        {
          property: "og:title",
          content: "FAQ - PharmaConnect : C'est Quoi PharmaConnect ?",
        },
        {
          property: "og:description",
          content: "PharmaConnect est une plateforme qui révolutionne la pharmacie en Tunisie en facilitant la communication entre les pharmaciens et les grossistes, rendant ainsi le secteur plus efficace.",
        },
        {
          property: "og:image",
          content: "https://www.pharmaconnect.net/images/faq-thumbnail.jpg",
        },
        {
          property: "og:url",
          content: "https://www.pharmaconnect.net/faq/what-is-pharmaconnect",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: "FAQ - PharmaConnect : C'est Quoi PharmaConnect ?",
        },
        {
          name: "twitter:description",
          content: "PharmaConnect révolutionne le secteur pharmaceutique en Tunisie en facilitant la communication et la gestion des commandes entre les pharmaciens et les grossistes.",
        },
        {
          name: "twitter:image",
          content: "https://www.pharmaconnect.net/images/faq-thumbnail.jpg",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.pharmaconnect.net/faq/what-is-pharmaconnect",
        },
      ],
      script: [
        // Structured Data using Schema.org
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": {
              "@type": "Question",
              "name": "C'est Quoi PharmaConnect ?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "PharmaConnect est une plateforme digitale qui facilite la communication entre pharmaciens et grossistes en Tunisie. Elle permet aux pharmaciens de passer leurs commandes auprès de différents grossistes en un seul endroit, avec des fonctionnalités innovantes pour une gestion efficace."
              }
            }
          },
        },
      ],
    };
  },


  components: {
    FaqQuestionAnswer,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: {},
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    fetchData() {
      // this.faqData = faqDataJS.data;
      const queryLowered = this.faqSearchQuery.toLowerCase()
      const filteredData = {}
      Object.entries(faqDataJS.data).forEach(entry => {
        const [categoryName, categoryObj] = entry
        // eslint-disable-next-line arrow-body-style
        const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
          return qAndAObj.question.toLowerCase().includes(queryLowered)
        })
        if (filteredQAndAOfCategory.length) {
          filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
        }
      })
      this.faqData = filteredData;
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
